<template>
  <div class="membership-list">
    <error :errors="membershipError" />
    <b-overlay :show="membershipBusy">
      <paginate :meta="membershipListMeta" place-holder="ئەزالىق نامىنى كىرگۈزۈپ ئىزدەڭ" @change-page="onChangePage" @refresh="refreshList">
        <b-button slot="action" :to="{name: 'membership.store'}" class="btn btn-label-success btn-sm border-0">
          يېڭىدىن قوشۇش
        </b-button>
      </paginate>
      <b-row>
        <b-col v-for="membership in membershipList" :key="`membership_${membership.id}`" md="3" sm="6">
          <portlet :title="membership.title" body-fluid body-class="px-3 pb-3 pt-0" headNoBorder head-class="px-2" head-size="sm">
            <template slot="toolbar">
              <b-dropdown v-b-tooltip title="مەشغۇلات" toggle-class="text-decoration-none border-0 btn btn-clean btn-sm btn-icon btn-icon-md" no-caret>
                <template v-slot:button-content>
                  <i class="fa fa-ellipsis-v"></i>
                </template>
                <b-dropdown-text >
                  <b-checkbox switch v-model="membership.is_show" @change="onChangeSwitch( membership.id, 'is_show', $event )"> ھالىتى </b-checkbox>
                </b-dropdown-text>
                <b-dropdown-divider />
                <b-dropdown-item :to="{name: 'membership.edit', params: {id: membership.id}}">
                  <i class="fa fa-info-circle"></i> تەھرىرلەش
                </b-dropdown-item>
                <b-dropdown-item @click="onClickDelete(membership.id)">
                  <i class="fa fa-trash"></i> ئۆچۈرۈش
                </b-dropdown-item>
              </b-dropdown>
            </template>
            <template slot="body">
              <div class="kt-widget-item-container">
                <div class="kt-widget kt-widget--user-profile-2">
                  <div class="kt-widget__body">
                    <div class="kt-widget__item pt-0">
                      <div class="kt-widget__contact">
                        <span class="kt-widget__label">باھاسى : </span>
                        <span class="kt-widget__data"> {{ membership.price ? `${membership.price} يۈەن` : 'تەڭگىگە ئالماشتۇرىدۇ' }} </span>
                      </div>
                      <div class="kt-widget__contact">
                        <span class="kt-widget__label">تەڭگە باھاسى : </span>
                        <span class="kt-widget__data"> {{ membership.score ? `${membership.score} تەڭگە` : 'نەق پۇلغا سېتىۋالىدۇ' }} </span>
                      </div>
                      <div class="kt-widget__contact">
                        <span class="kt-widget__label">مۇددىتى : </span>
                        <span class="kt-widget__data"> {{ membership.duration }} كۈن </span>
                      </div>
                      <div class="kt-widget__contact">
                        <span class="kt-widget__label">سېتىلىشى : </span>
                        <span class="kt-widget__data"> {{ membership.orders_count }} دانە </span>
                      </div>
                      <div class="kt-widget__contact">
                        <span class="kt-widget__label">تەۋە بۆلەك : </span>
                        <span class="kt-widget__data" v-if="membership.belongs === 'any'">ھەممىسىدە كۆرۈنىدۇ</span>
                        <span class="kt-widget__data" v-if="membership.belongs === 'miniprogram'">ئەپچاقتىلا كۆرۈنىدۇ</span>
                        <span class="kt-widget__data" v-if="membership.belongs === 'app'">ئەپتىلا كۆرۈنىدۇ</span>
                        <span class="kt-widget__data" v-if="membership.belongs === 'wechat'">سالون بەتتىلا كۆرۈنىدۇ</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </portlet>
        </b-col>
      </b-row>
      <no-result :visible="!membershipList.length" background="warning" title="ئۇچۇر تېپىلمىدى" desc="مۇناسىۋەتلىك ئ‍ۇچۇر تېپىلمىدى، يېڭىدىن ئەزالىق تۈرى قوشۇڭ." >
        <template slot="action">
          <b-button :to="{name: 'membership.store'}" class="btn-custom btn-bold btn-upper btn-font-sm  btn-warning">VIP ئەزالىق تۈرى قوشۇش</b-button>
        </template>
      </no-result>
    </b-overlay>
  </div>
</template>

<script>
  import BreadCrumbs from "@c@/breadcrumbs.service";
  import Error from "@v@/partials/widgets/Error";
  import paginate from "@v@/components/paginate";
  import Portlet from "@v@/partials/content/Portlet";
  import NoResult from "@v@/partials/content/NoResult";
  import membershipMixin from "@m@/membership.mixin";
  import sweetAlert from "@m@/common/sweetAlert.mixin";
  import commonMethodMixin from "@m@/common/common.method.mixin";
  import msgBoxConfirmMixin from "@m@/common/msgBoxConfirmMixin";
  import formBusyMixin from "@m@/common/formBusy.mixin";
  import {SET_MEMBERSHIP_ERROR} from "@/store/modules/membership.module";
  export default {
    name: "index",
    mixins: [ sweetAlert, commonMethodMixin, msgBoxConfirmMixin, membershipMixin, formBusyMixin ],
    components: { Error, Portlet, paginate, NoResult },
    created() {
      this.getMemberships()
    },
    mounted() {
      BreadCrumbs.setBreadCrumb([]);
      BreadCrumbs.addBreadCrumb({title: "VIP ئەزالىق تۈرى باشقۇرۇش", route: {name: 'membership.home'}});
      BreadCrumbs.addBreadCrumb({ title: "بارلىق VIP تۈرلىرى" });
    },
    data(){
      return {
        membershipBusy: false,
      };
    },
    methods: {
      onChangePage(page){
        this.getMemberships(page);
      },
      onClickDelete(id) {
        this.msgBoxConfirm({
          message: 'مەزكۇر ئەزالىق تۈرىنى ئۆچۈرسىڭىز ئەسلىگە قايتۇرالمايسىز، يەنىلا ئۆچۈرۈشنى جەزىملەشتۈرەمسىز ؟',
          confirmCallback: confirm=>{
            if ( confirm ){
              this.deleteModel({
                id, mutationType: SET_MEMBERSHIP_ERROR, resource: 'memberships',
                begin: ()=>{ this.showBusy('membershipBusy')},
                after: ()=>{
                  let { title, page } = this;
                  this.getMemberships(page, title);
                  this.hideBusy('membershipBusy');
                },
              })
            }
          }
        });
      },
      onChangeSwitch(id, column, value){
        this.switchColumn({
          resource: 'memberships',
          id, column, mutationType: SET_MEMBERSHIP_ERROR,
          begin: ()=>{ this.showBusy('membershipBusy') },
          after: ()=>{ this.hideBusy('membershipBusy') },
        });
      },
      refreshList(title){
        let page = this.membershipListMeta.current_page;
        this.getMemberships(page, title);
      },
    }
  }
</script>
