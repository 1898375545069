<template>
  <div class="create-membership">
    <error :errors="membershipError" />
    <b-overlay :show="membershipBusy">
      <portlet title="يېڭىدىن ئەزالىق تۈرى قوشۇش">
        <template v-slot:body>
          <membership-form
            ref="membership"
            @storing="onStoring"
            @stored="onStored"
          />
        </template>
        <template v-slot:foot>
          <b-button id="submit" @click="handleSubmit" variant="primary">
            ساقلاش
          </b-button>
          <b-button @click="onClickReset" class="ml-2" variant="danger">
            بىكار قىلىش
          </b-button>
        </template>
      </portlet>
    </b-overlay>
  </div>
</template>

<script>
  import Portlet from "@v@/partials/content/Portlet";
  import BreadCrumbs from "@c@/breadcrumbs.service";
  import MembershipForm from "@v@/components/forms/MembershipForm";
  import Error from "@v@/partials/widgets/Error";
  import {debugConsole} from "@c@/helpers/helpers";
  import { mapGetters } from "vuex";
  import formBusyMixin from "@m@/common/formBusy.mixin";
  export default {
    name: "index",
    components: { Portlet, MembershipForm, Error },
    mixins: [ formBusyMixin ],
    computed: {
      ...mapGetters(["membershipError"])
    },
    data(){
      return {
        membershipBusy: false,
        form: {}
      };
    },
    mounted() {
      BreadCrumbs.setBreadCrumb([]);
      BreadCrumbs.addBreadCrumb({title: "VIP ئەزالىق تۈرى باشقۇرۇش", route: {name: 'membership.home'}});
      BreadCrumbs.addBreadCrumb({ title: "يېڭىدىن قوشۇش" });
      if ( this.$refs.hasOwnProperty('membership') ){
        this.form = this.$refs['membership'];
      }
    },
    methods: {
      handleSubmit(){
        this.form.onSubmit();
      },
      onStoring(){
        this.showBusy('membershipBusy');
        debugConsole({prefix: 'membership', message: `onStored`});
      },
      onStored(state){
        this.hideBusy('membershipBusy');
        debugConsole({prefix: 'membership', message: `onStored: ${state}`});
      },
      onClickReset(){
        this.$router.replace({name: 'membership.home'});
      },
    }
  }
</script>
